<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Exercice Préparatoire pour l'Expérience 4</h2>

      <p class="mb-n3">
        Le but de ce questionnaire est de vous préparer à effectuer l'expérience. Il faut obtenir
        <b>la note minimale de 80%</b> des points AVANT d'arriver à votre séance de laboratoire afin
        d'être capable de compléter l'expérience, sinon vous ne recevrez pas la permission
        d'effectuer l'expérience.
      </p>

      <v-radio-group v-model="inputs.requirements" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRequirements"
          :key="'pt-0-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        a) Dans cette expérience, pourquoi est-il obligatoire de porter les lunettes protectrices?
        Choisissez la meilleure réponse.
      </p>

      <v-radio-group v-model="inputs.inputA" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsA"
          :key="'pt-1-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) Dans cette expérience, vous déterminerez la concentration d'un acide diprotique inconnu à
        partir d'une base étalonnée. Quelle est la meilleure représentation de l'équation chimique
        équilibrée pour cette réaction?
      </p>

      <v-radio-group v-model="inputs.inputB" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsB"
          :key="'pt-2-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Pourquoi est-il nécessaire d'étalonner la solution de la base aussitôt qu'elle est
        préparée? Sélectionnez la meilleure réponse.
      </p>

      <v-radio-group v-model="inputs.inputC" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsC"
          :key="'pt-3-' + option.value"
          class="d-inline-block mb-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) En préparant la solution de la base, pourquoi est-ce qu'on ajoute la base à l'eau et non
        pas l'eau à la base? Sélectionnez toutes les raisons valides.
      </p>

      <p v-for="option in optionsD" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputD" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-6">
        e) En faisant des titrages, quelles parmi les options ci-dessous, seront considérée(s) des
        bonnes techniques. Sélectionnez toutes les réponses correctes.
      </p>

      <p v-for="option in optionsE" :key="'pt-5-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputE" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaPrelab4Fr',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        requirements: null,
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: [],
        inputE: [],
      },
      optionsRequirements: [
        {
          text: "Je comprends et j'accepte le besoin de la note minimale pour le questionnaire.",
          value: 'agree',
        },
        {
          text: "Je n'accepte pas le besoin d'obtenir la note minimale pour le questionnaire.",
          value: 'disagree',
        },
      ],
      optionsA: [
        {
          text: 'Vous travaillez avec des bases concentrées.',
          value: 'concentratedBases',
        },
        {text: 'Vous travaillez avec des acides.', value: 'acids'},
        {
          text: 'Vous travaillez au laboratoire et il est toujours obligatoire de porter les lunettes protectrices au laboratoire.',
          value: 'alwaysWearThem',
        },
      ],
      optionsB: [
        {
          text: "L'Acide diprotique + la base produira un composé ionique et de l'eau",
          value: 'wordForm',
        },
        {
          text: '$\\ce{H2A(aq) + NaOH(aq) -> NaA(aq) + 2 H2O(l)}$',
          value: '1equivBase',
        },
        {
          text: '$\\ce{H2A(aq) + 2 NaOH(aq) -> Na2A(aq) + 2 H2O(l)}$',
          value: '2equivBase',
        },
        {
          text: '$\\ce{HA(aq) + NaOH(aq) -> NaA(aq) + H2O(l)}$',
          value: '1to1mono',
        },
      ],
      optionsC: [
        {text: "Il n'y a pas beaucoup de temps pour effectuer l'expérience.", value: 'noTime'},
        {
          text: "La solution de la base n'est pas stable pendant longtemps.",
          value: 'unstable',
        },
        {
          text: 'La concentration est la valeur la plus importante mesurée.',
          value: 'importantValue',
        },
      ],
      optionsD: [
        {
          text: 'La base est corrosive et glissante.',
          value: 'corrosiveSlippery',
        },
        {text: "La densité de la base est plus élevée que celle de l'eau.", value: 'moreDense'},
        {text: "Il est plus probable qu'on produira une solution homogène.", value: 'lessVolume'},
        {
          text: "Il est plus probable qu'on produira une solution homogène.",
          value: 'moreHomogeneous',
        },
        {
          text: "En brassant la solution, la chaleur produit quand la base et l'eau se mélange peut être mieux dispersée.",
          value: 'heat',
        },
      ],
      optionsE: [
        {
          text: "Ajouter l'indicateur au début.",
          value: 'indicatorAtStart',
        },
        {
          text: 'Parler à votre binôme en ajoutant la base.',
          value: 'talkToFriend',
        },
        {
          text: "Effectuer plusieurs essais afin de s'assurer que la concentration finale déterminée est précisée à 2% d'erreur.",
          value: 'multipleTrials',
        },
        {
          text: 'Soigneusement, noter le volume de la base ajouté.',
          value: 'carefulMeasurementVolume',
        },
        {
          text: "Ajouter la base plus rapidement au début, et ralentir l'ajoute vers le point de virage.",
          value: 'fastThenSlow',
        },
        {
          text: "Attendre 30 s après le premier changement de couleur afin de s'assurer que le point de virage est atteint.",
          value: 'wait30secAfterFirstColorChange',
        },
        {text: 'Brasser la solution constamment.', value: 'alwaysStir'},
        {
          text: "Attendre quelques minutes après l'ajoute de chaque goutte de la solution de la base.",
          value: 'waitFewMinAfterEachDrop',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
